import { useMemo } from 'react';
import { DiningOptionEnum, IQsrOrder } from '@/views/QsrOrders/types';
import { useRestaurantContext } from '@/contexts/restaurant';
import { useTranslation } from '@/hooks/translations';

import styles from './index.module.scss';

interface IProps {
    order: IQsrOrder;
}

const QsrDineOption = ({ order }: IProps) => {
    const { t } = useTranslation('common');
    const { restaurant } = useRestaurantContext();

    const text = useMemo(() => {
        switch (order.diningOption) {
            case DiningOptionEnum.DINING:
                return t('Dine-in');
            case DiningOptionEnum.TAKEAWAY:
                return t('Dine In');
            default:
                return t('Unknown');
        }
    }, [order]);

    if (!restaurant?.order_config?.diningOptionSelectionEnable) {
        return null;
    }

    return (
        <div className={styles.container}>
            <strong>{t('Dining Option:')}</strong> {text}
        </div>
    );
};

export default QsrDineOption;
